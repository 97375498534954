<template>
    <span class="text-nowrap">
        <span v-if="text" class="text-pronunciation">
            {{ pronunciation }}
        </span>
        <PronunciationSpeaker
            v-for="voice in voices"
            :key="voice"
            :pronunciation="pronunciation"
            :voice="voice"
            class="btn btn-sm btn-link px-1 py-0"
        />
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';

export default defineComponent({
    props: {
        pronunciation: { required: true, type: String },
        text: { default: false, type: Boolean },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
    computed: {
        voices(): string[] {
            if (this.config.pronunciation?.enabled) {
                return Object.keys(this.config.pronunciation.voices);
            } else {
                return [];
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import "assets/variables";

.text-pronunciation {
    font-weight: normal;
    color: var(--#{$prefix}secondary-color);
}
</style>
